<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import { ref, onMounted, onUnmounted, watch } from "vue";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";
import { decodeHTML } from "entities";
import type { CSSProperties, VNode, VNodeArrayChildren } from "vue";
import type { NodeObject } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import { getOptionsFromNode } from "@shopware/cms-base-layer/helpers/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@shopware/cms-base-layer/helpers/html-to-vue/renderToHtml";
type RawChildren = string | number | boolean | VNode | VNodeArrayChildren;

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const {
  headlineText,
  toplineText,
  toplineType,
  headlineType,
  mainText,
  videoLink,
  videoPosterImage,
} = props.content.data;

const mappedContent = computed<string>(() => {
  return mainText;
});

const context = getCurrentInstance();
const videoUrl = ref("");

onMounted(() => {
  videoUrl.value = videoLink; // Set the value inside onMounted
});

const CmsTextRender = defineComponent({
  setup() {
    const { resolveUrl } = useUrlResolver();

    const config = {
      textTransformer: (text: string) => decodeHTML(text),
      extraComponentsMap: {
        link: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              !node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            const _class = node.attrs?.class;
            return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        button: {
          conditions(node: NodeObject) {
            return (
              node.type === "tag" &&
              node.name === "a" &&
              node.attrs?.class?.match(/btn\s?/)
            );
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            let _class = "";
            if (node?.class) {
              const btnClass = "";

              _class = node.class
                .replace("btn-secondary", `${btnClass} btn-outline`)
                .replace("btn-primary", `${btnClass} btn`);
            }

            return createElement(
              "a",
              {
                class: _class,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        font: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "font";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            // convert from <font color="#ce0000">Headline 1</font> to <span style="color:#ce0000">Headline 1</span>
            let newStyle = null;
            const styleColor = node?.attrs?.color;
            if (styleColor) {
              const currentStyle = node.attrs?.style ?? "";
              newStyle = `color:${styleColor};${currentStyle}`;
              delete node.attrs?.color;
            }

            return createElement(
              "span",
              {
                style: newStyle,
                ...getOptionsFromNode(node, resolveUrl).attrs,
              },
              [...children]
            );
          },
        },
        img: {
          conditions(node: NodeObject) {
            return node.type === "tag" && node.name === "img";
          },
          renderer(
            node: NodeObject,
            children: RawChildren[],
            createElement: typeof h
          ) {
            return createElement(
              "img",
              getOptionsFromNode(node, resolveUrl)?.attrs
            );
          },
        },
      },
    };
    const rawHtml =
      mappedContent.value?.length > 0
        ? mappedContent.value
        : "<div class='cms-element-text missing-content-element'></div>";

    return () =>
      h("div", {}, renderHtml(rawHtml, config, h, context, resolveUrl));
  },
});
</script>
<template>
  <section>
    <div class="container">
      <div class="flex flex-wrap items-center -mx-4 gap-y-10">
        <div class="w-full md:w-[58%] px-0 xl:px-4">
          <SharedTopline
            :headline="toplineText"
            :headlineType="toplineType"
          ></SharedTopline>
          <SharedHeadline
            :headline="headlineText"
            :headlineType="headlineType"
            class="font-bold text-2xl mb-7.5"
          ></SharedHeadline>

          <div class="user-content">
            <CmsTextRender />
          </div>
        </div>
        <div class="w-full md:w-[42%] px-0 xl:px-4">
          <figure class="relative rounded overflow-hidden">
            <SharedFancybox :options="{}">
              <a
                data-fancybox=""
                :href="videoUrl"
                class="w-full h-full flex justify-center items-center absolute top-0 left-0 z-10 group"
                :class="{ 'pointer-events-none': !videoUrl }"
              >
                <button
                  v-if="videoUrl"
                  type="button"
                  class="btn-play transition-all duration-300 group-hover:scale-110"
                ></button>
                <span v-else class="absolute text-2xl ml-2">
                  <i class="fa-solid fa-spinner animate-spin text-white"></i>
                </span>
              </a>
            </SharedFancybox>
            <div
              class="w-full h-full absolute top-0 left-0 z-0 bg-gradient-primary"
            ></div>
            <NuxtImg
                loading="lazy"
                format="webp"
                width="400"
              v-if="videoPosterImage"
              :src="videoPosterImage.media.url"
              alt=""
              class="w-full rounded"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>
